<template>
    <div class="store-categories" style="z-index: 1">
        <div class="d-none d-lg-block">
            <div class="d-flex flex-column flex-sm-row flex-wrap" @mouseleave="mouseLeave">
                <div class="mr-2 mt-1" v-for="item in categories" :key="item.id">
                    <span class="main-category" @mouseover="showCategories($event, item)" @click="onSelectSubCategory(item.id)" :style="[mainCategory !== null && mainCategory === item ? {'color': 'black', 'font-weight': '700'}: mainCategory !== null ? {'color':'#ABABAB'}:{}]">
                        {{item.title}}
                        <FeatherIcon :style="[mainCategory === item ? {'opacity':'1'}:{'opacity':'0'}]" icon="ChevronDownIcon"/>
                    </span>
                </div>
            </div>

            <div class="position-relative" v-if="show && mainCategory !== null" style="z-index: 2;">
                <div class="children" @mouseleave="hide">
                    <div class="d-flex flex-column flex-sm-row flex-wrap">
                        <div class="mr-3 mt-1" v-for="(child1, index) in mainCategory.children" :key="'child_1_'+index">
                            <a class="child-category" @click="onSelectSubCategory(child1.id)">{{child1.title}}</a>
                            <div class="mr-3 mt-1" v-for="(child2, index) in child1.children" :key="'child_2_'+index">
                                <a class="child2-category" @click="onSelectSubCategory(child2.id)">{{child2.title}}</a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="d-lg-none">
            <div class="text-center text-md-right">
                <rounded-button class="filters-button" padding="11px 20px" @click="mobileShow ? mobileShow = false : mobileShow = true">
                    Kategorije
                </rounded-button>
            </div>
            <div v-if="mobileShow" class="mt-1">
                <app-collapse accordion>
                    <store-categories-collapse class="main" v-for="main in categories" :key="main.id" :title="main.title" :id="main.id" @on-select-sub-category="onSelectSubCategory">
                        <app-collapse accordion>
                            <store-categories-collapse class="child1" v-for="child1 in main.children" :key="child1.id" :title="child1.title" :id="child1.id" @on-select-sub-category="onSelectSubCategory">
                                <div class="child2-mobile" v-for="(child2, index) in child1.children" :key="'child_2_'+index" @click="onSelectSubCategory(child2.id)">{{child2.title}}</div>
                            </store-categories-collapse>
                        </app-collapse>
                    </store-categories-collapse>
                </app-collapse>
            </div>
        </div>
    </div>
</template>

<script>
    import RoundedButton from '@/views/Components/RoundedButton'
    import AppCollapse from '@core/components/app-collapse/AppCollapse.vue'
    import StoreCategoriesCollapse from '@/views/Components/StoreCategoriesCollapse'
    export default {
        components: {
            StoreCategoriesCollapse,
            RoundedButton,
            AppCollapse
        },
        props: {
            categories: {
                type: Array
            }
        },
        data() {
            return {
                mainCategory: null,
                show: false,
                category: null,
                hoverScreenY: 0,
                mobileShow: false
            }
        },
        methods: {
            showCategories(e, item) {
                this.show = true
                this.mainCategory = item
                this.hoverScreenY = e.screenY
            },
            mouseLeave(e) {
                if (e.screenY < this.hoverScreenY) this.hide()
            },
            hide() {
                this.show = false
                this.mainCategory = null
            },
            onSelectSubCategory(id) {
                this.$router.push({name: 'web-shop-category-items-list', params: {'category_id': id}})
                this.$emit('category-changed')

                if (this.mobileShow) this.mobileShow = false
            }
        }
    }
</script>

<style scoped>
.main-category {
  font-weight: 600;
  font-size: 20px;
  line-height: 25px;
  cursor: pointer;
}
.main-category2 {
  font-weight: 400;
  font-size: 20px !important;
  line-height: 32px !important;
  color: black !important;
  cursor: pointer;
}
.child-category {
  font-weight: 400;
  font-size: 20px;
  line-height: 25px;
  cursor: pointer;
}
.child2-category {
  font-weight: 400;
  font-size: 17px;
  line-height: 18px;
  cursor: pointer;
  color: #848484;
}
.children {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  padding: 1rem;
  background: rgba(255, 255, 255, 0.96);
  z-index: 3;
}
.main-category:hover {
    color: #8F8F8F !important;
}
</style>
<style>
.store-categories .main .collapse-title {
  font-weight: 800 !important;
  font-size: 18px !important;
}
.store-categories .child1 .collapse-title {
  font-weight: 400 !important;
  font-size: 18px !important;
}
.store-categories .collapse-title svg {
  display: none !important;
}
.store-categories .child2-mobile {
  font-size: 16px;
  color: #848484;
  padding: 0.2rem 1rem !important;
  cursor: pointer;
}
.store-categories .card-header {
  padding: 0.8rem 2.8rem 0.8rem 1rem !important;
}
.store-categories .card-body {
  padding-top: 0 !important;
}
.store-categories .card {
  box-shadow: none !important;
}
.store-categories .main .card {
  border: none !important;
}
</style>